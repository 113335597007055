import { Container, ErrorBoundary, Head } from "@/components";
import { useCtPdp } from "@jog/react-components";
import { usePrismicPdp } from "@jog/react-components";
import { getDomain } from "@jog/shared";
import { useMemo } from "react";
export const Main = ({ productData, sku, children }) => {
    const { name, description, desktopImages, color, size, material, paths } = useCtPdp();
    const { metaTagRobots } = usePrismicPdp();
    const productStructuredDataJSON = useMemo(() => {
        var _a, _b, _c, _d;
        if (!productData)
            return null;
        const domain = getDomain();
        const product = {
            "@context": "https://schema.org",
            "@type": "Product",
            "@id": `${domain}/#Product`,
            name,
            image: desktopImages.map((val) => val === null || val === void 0 ? void 0 : val.url),
            offers: {
                "@type": "Offer",
                price: (_b = ((_a = productData.price) === null || _a === void 0 ? void 0 : _a.centAmount) / 100) !== null && _b !== void 0 ? _b : 0,
                priceCurrency: (_d = (_c = productData.price) === null || _c === void 0 ? void 0 : _c.currencyCode) !== null && _d !== void 0 ? _d : "",
                availability: "https://schema.org/InStock",
                itemCondition: "https://schema.org/NewCondition",
            },
            description,
            color,
            brand: productData.brand,
            sku,
            size,
            material,
        };
        return JSON.stringify(product);
    }, [color, description, desktopImages, material, name, productData, size, sku]);
    return (<ErrorBoundary system="PDP">
            <Head alternate={paths} title={`${name} | Jeans Centre`} description={description} robots={metaTagRobots}>
                {productStructuredDataJSON && <script type="application/ld+json">{productStructuredDataJSON}</script>}
            </Head>
            <Container>{children}</Container>
        </ErrorBoundary>);
};
