import { LogoLoader, SizeSelector } from "@/components";
import { Favorite } from "@/components/Favorite";
import { Ruler } from "@/components/Icon/Ruler";
import { SizeModalButton } from "@/components/Pdp/SizeModal";
import { useProductDetail } from "@/hooks/useProductDetail";
import { useProductReviews } from "@/hooks/useProductReview";
import { usePromotionTag } from "@/lib/preFetch/prefetchPromoTag";
import { useWishlist } from "@/lib/store/wishlist";
import { useStores } from "@/stores";
import { FasletWidget, PdpLoader, genPdpImageStyle, useAddedProduct, useCtPdp } from "@jog/react-components";
import { gtmAddPdpProductToCart, gtmAdpageAddToCart, gtmAdpageItemDetail, gtmAdpageItemSizeDetail, gtmPdpProductData, } from "@jog/shared";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AddToCart } from "./AddToCart";
import { Attributes } from "./Attributes";
import { Carousel } from "./Carousel";
import { CheckMarks } from "./CheckMarks";
import { Description } from "./Description";
import { GroupSelector } from "./GroupSelector";
import { Header } from "./Header";
import { Main } from "./Main";
import { Reviews } from "./Reviews";
import { SizeConflict } from "./SizeConflict";
import { Sustainable } from "./Sustainable";
export const Pdp = observer(() => {
    var _a;
    const { cartStore } = useStores();
    const wishlistLoading = useWishlist.use.actionPending();
    const { gtmPrice, promotionalBanner, productData, pending, variants } = useProductDetail();
    const { sizeImage, ctpId, isFasletShow, gtmId, productCategoryPath, name, desktopImages, color } = useCtPdp();
    const [sku, setSku] = useState("");
    const promotionTag = usePromotionTag(promotionalBanner);
    const { reviews } = useProductReviews();
    useEffect(() => {
        sku && productData && gtmAdpageItemSizeDetail(productData, gtmId, productCategoryPath, sku);
    }, [gtmId, productCategoryPath, productData, sku]);
    useEffect(() => {
        productData && gtmPdpProductData(productData, gtmId, productCategoryPath);
        productData && gtmAdpageItemDetail(productData, gtmId, productCategoryPath);
    }, [gtmId, productCategoryPath, productData]);
    const [fasletAvailable, setFasletAvailable] = useState(false);
    const { addedProduct } = useAddedProduct();
    const onAdd = useCallback(() => {
        cartStore.saveProductExtras(ctpId, window.location.href, productData);
        gtmAddPdpProductToCart(productData, productCategoryPath);
        addedProduct(sku);
        gtmAdpageAddToCart(productData, sku, gtmId, productCategoryPath);
    }, [addedProduct, cartStore, ctpId, gtmId, productCategoryPath, productData, sku]);
    const style = useMemo(() => genPdpImageStyle(15, { lg: 60, xl: 80, xxl: 100 }, desktopImages.length), [desktopImages.length]);
    return (<Main productData={productData} sku={sku}>
            {(cartStore.addProductFetching || wishlistLoading) && <LogoLoader />}
            <div style={style} className="mb-3.75 grid grid-cols-1 gap-3.75 lg:grid-cols-[var(--lg-carousel-w),1fr] xl:grid-cols-[var(--xl-carousel-w),1fr] 2xl:grid-cols-[var(--xxl-carousel-w),1fr]">
                <div className="col-span-1 bg-white">
                    <Carousel favorite={<Favorite productId={ctpId} name={name} price={gtmPrice} className="bottom-6 right-4" gtmId={gtmId}/>}/>
                </div>
                <div className="col-span-1 grid h-fit gap-y-5 bg-white px-5 lg:gap-y-7.5 lg:p-7.5">
                    <Header reviews={reviews} productData={productData} promotionTag={promotionTag}/>
                    {pending ? (<PdpLoader />) : (<div className="-mt-4">
                            <GroupSelector variants={variants} color={color} currentId={productData.id}/>
                            <SizeSelector sizes={productData.sizes} setSku={setSku}/>
                            {isFasletShow ? (<FasletWidget key={ctpId} gtmId={gtmId} pdpData={productData} productName={name} productImage={((_a = desktopImages === null || desktopImages === void 0 ? void 0 : desktopImages[0]) === null || _a === void 0 ? void 0 : _a.url) || ""} productId={ctpId} cartStore={cartStore} setSku={setSku} Icon={<Ruler width={20} height={20}/>} fasletAvailable={fasletAvailable} setFasletAvailable={setFasletAvailable} className="text-sm" onAdd={onAdd}/>) : !!sizeImage ? (<SizeModalButton />) : null}
                            <SizeConflict sku={sku} fasletAvailable={fasletAvailable}/>
                        </div>)}

                    <AddToCart onAdd={onAdd} sku={sku}/>

                    <Description />
                    <Attributes />
                    <CheckMarks />
                    <Sustainable />
                </div>
            </div>

            {/* CONTAINER - Recommended product from sqzly */}
            <div className="sqzly-recommended-product"/>
            {/* CONTAINER - product history from sqzly */}
            <div className="sqzly-product-history"/>

            <Reviews reviews={reviews}/>
        </Main>);
});
