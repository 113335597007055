import { Price, SizeSelector } from "@/components";
import { useStores } from "@/stores";
import { Arrow, Link, NextImage, getProductUrl, useAddedProduct, useI18n } from "@jog/react-components";
import { observer } from "mobx-react";
import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";
export const Product = observer(({ product, sizes }) => {
    var _a, _b, _c, _d;
    const { cartStore } = useStores();
    const [sku, setSku] = useState("");
    const isLoggedIn = useSession().status === "authenticated";
    const { $t } = useI18n();
    const [noSize, setNoSize] = useState(false);
    useEffect(() => {
        sku && setNoSize(false);
    }, [sku]);
    const { addedProduct } = useAddedProduct();
    const addProductToCart = async () => {
        if (!sku) {
            setNoSize(true);
            return;
        }
        await cartStore.addProductToCart(isLoggedIn, sku);
        addedProduct(sku);
    };
    const firstPrice = ((_c = (_b = (_a = product.attributes) === null || _a === void 0 ? void 0 : _a.find((val) => { var _a; return ((_a = val.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "firstprice" && val.values; })) === null || _b === void 0 ? void 0 : _b.values) === null || _c === void 0 ? void 0 : _c[0]) || "0";
    const hasDiscount = (_d = product.attributes) === null || _d === void 0 ? void 0 : _d.find((val) => val.name === "discountName" && val.values);
    const linkItem = getProductUrl(product);
    return (<div className="mb-7.5 border-b border-b-gray-silver last:border-b-0">
            <div className="grid grid-cols-12 grid-rows-[auto_1fr]">
                <div className="col-span-12 lg:col-span-6 lg:col-start-6">
                    <h1 className="pb-0 text-[22px] uppercase text-gray-base lg:pb-2.5 lg:text-[21px]">
                        {product.title}
                    </h1>
                </div>
                <div className="col-span-12 lg:col-span-5 lg:row-span-2 lg:row-start-1">
                    <Link to={linkItem} external target="_blank" rel="noopener">
                        <NextImage className="img-fluid" src={product.image} alt="Product image" height={240} width={160}/>
                    </Link>
                </div>
                <div className="col-span-12 lg:col-span-6 lg:col-start-6 lg:row-start-2">
                    <div className="flex w-full flex-wrap pt-5 ">
                        {hasDiscount && Number(firstPrice) > Number(product.price) ? (<>
                                <Price fontSize="15px" isStlPrice isOldPrice price={firstPrice}/>
                                <Price color="#f00" fontSize="23px" marginLeft="5px" isStlPrice price={product.price}/>
                            </>) : firstPrice && Number(firstPrice) > Number(product.price) ? (<>
                                <Price fontSize="15px" isOldPrice isStlPrice price={firstPrice}/>
                                <Price color="#f00" fontSize="23px" marginLeft="5px" isStlPrice price={product.price}/>
                            </>) : (<Price fontSize="2.1em" price={product.price} isStlPrice/>)}
                    </div>
                    <SizeSelector isInSTL sizes={sizes} setSku={setSku}/>
                    <div className="mb-7.5">
                        <div className="mt-2.5 ml-0 flex w-full flex-col md:m-0 md:w-auto">
                            {noSize ? (<span className="mb-1 h-5.5 text-left text-base text-red-main">
                                    {$t("Select your size")}
                                </span>) : null}

                            <button onClick={addProductToCart} className="group block w-full bg-green-main py-2.5 px-10 text-center font-primary text-base font-bold uppercase text-white opacity-100 hover:bg-orange-main focus:outline-none md:w-auto">
                                <span className="relative">
                                    <Arrow className="absolute top-1/2 -left-5 -translate-y-1/2 duration-300 group-hover:-left-3" color="white" size="middle"/>
                                    <span className="pl-1.5">{$t("Add To Cart")}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
});
